<template>
  <div class="details" v-if="loading">
    <div class="hero-1">
      <img class="banner" :src=this.data.hero_banner>
    </div>
    <div class="container">
      <div class="presentation">
        <div class="title">
          <h4 class="d-flex justify-content-center"><b> {{this.data.name}}</b> </h4>
          <h6 class="d-flex justify-content-center" style="color:orange"><b>{{ this.data.category.name }}</b></h6>
          <a :href=this.data.iframe_url ><button class=" btn-lg mx-auto d-block see-more d-flex justify-content-center" ><p class="button-blue" style="color:white" > > &nbsp; Play the game</p></button></a>
        </div>
        
        <div class="row" style="padding-bottom:3rem">
            <div class="col-md-4">
              <img width="100%" :src=this.data.logo >
            </div>
            
            <div class="col-md-4">
              <p style="text-align:left">{{ this.data.description}} </p>  
            </div>
            <div class="col-md-4">
              <div class="carousel">  
                <a class="play-button" @click="modalFirst = !modalFirst">
                  <div class="play-vid">
                      <img src="../assets/play_video_btn.png" alt="" width="50%">
                  </div>
                 </a>
                <div id="slider-mobile">
                  <img class="iphone-frame" src="../assets/iphone_frame.png" alt="">
                  <transition-group tag="div" :name="transitionName_mobile" class="slides-group-mobile">
                    <div v-if="show_mobile" :key="current_mobile" class="slide-mobile" >
                      <img class="slide-photo"  :src=slides_mobile[current_mobile].thumbnail_path >
                    </div>
                  </transition-group>
                  <div class="btn-mobile btn-prev-mobile" aria-label="Previous slide mobile" @click="slide_mobile(-1)">
                    &#10094;
                  </div>
                  <div class="btn-mobile btn-next-mobile" aria-label="Next slide mobile" @click="slide_mobile(1)">
                    &#10095;
                  </div>
                </div>
                
              </div>
            </div>
 
        </div>
      </div> 
    </div>
      <div class="row data" >
        <div class="col-md-6" style="padding-top:3rem;text-align:center;">
            <img class="icon-details" :src=this.data.icon alt="">
        </div>
        <div class="col-md-6 game-data"> 
          <h4><b>General game attributes</b></h4><br>
          <div class="data-div"><span>Reels</span><span class="span-right" >{{ this.data.reels }} </span> </div>
          <div class="data-div"><span>Lines</span><span class="span-right" >{{ this.data.lines }} </span> </div>
          <div class="data-div"><span>Features</span><span class="span-right" >{{ this.data.features }} </span> </div>
          <div class="data-div"><span>RTP</span><span class="span-right" >{{ this.data.rtp }} </span> </div>
          <div class="data-div"><span>Volatility</span><span class="span-right" >{{ this.data.volatility }} </span> </div>
          <div class="data-div"><span>Hit frequency</span><span class="span-right" >{{ this.data.hit_frequency }} </span> </div>
          <div class="data-div"><span>Min Bet</span><span class="span-right" >{{ this.data.min_bet }} </span> </div>
          <div class="data-div"><span>Max Bet</span><span class="span-right" >{{ this.data.max_bet }} </span> </div>
          <br>
          <div><a style="color:white" href=""> > Download Game Assets </a></div>

        </div>
      </div>
      <div class="container">
        <div class="carousel">  
          <div id="slider">
            <transition-group tag="div" :name="transitionName" class="slides-group">
              <div v-if="show" :key="current" class="slide" :class="slides[current].className">
                <img style="width:50%" :src=slides[current].thumbnail_path >
              </div>
            </transition-group>
            <div class="btn btn-prev" aria-label="Previous slide" @click="slide(-1)">
              &#10094;
            </div>
            <div class="btn btn-next" aria-label="Next slide" @click="slide(1)">
              &#10095;
            </div>
          </div>
          
        </div>
        <a :href=this.data.iframe_url ><button class=" btn-lg mx-auto d-block see-more d-flex justify-content-center" ><p class="button-blue" style="color:white" > > &nbsp; Play the game</p></button></a>
      </div>
 
  <div style="position:relative">
      <img class="landing_4" src="../assets/banner3_main.png">
      <button class=" btn-lg mx-auto d-block see-more-3" ><p class="button-blue" ><router-link style="color:black" to="/GamesPortofolio"> > &nbsp; See more</router-link></p></button>
  </div>
  

      <modal
          
          v-show="modalFirst"
          @close="modalFirst = false">
          <div slot="body">
              <iframe width="420" height="315"
                :src=this.data.youtube_url >
                </iframe>
              <!-- <button class="btn btnPrimary" @click="modalFirst = !modalFirst">Close</button> -->
          </div>
      </modal>

  </div>

  
</template>

<script>
// @ is an alias to /src
// import $route from 'vue-router'
import axios from 'axios'
import modal from '@/components/Modal.vue'

export default {
   data() {
    return {
      modalFirst: false,
      data : [] ,
      current: 0,
      direction: 1,
      transitionName: "fade",
      current_mobile: 0,
      direction_mobile: 1,
      transitionName_mobile: "fade",
      show: false,
      show_mobile: false , 
      slides: [] ,
      slides_mobile: [] ,
      loading : false
    }
  },
  mounted() {    
    this.data = []
    console.log(this.$route.params.id)    
    axios
      .get('https://flipluck.com/api/v1/game?game_id='+this.$route.params.id)
      .then(response => {
        this.data = response.data.game;
        this.slides = this.data.galleries.desktop
        this.slides_mobile = this.data.galleries.mobile
        if(this.slides_mobile.length != 0 ){
          this.show_mobile = true;
        }
        if(this.slides.length != 0 ){
          this.show = true;
        }
        // console.log(this.data.galleries.desktop)
      }) 
      .finally(() => (this.loading = true)) //when the requests finish
      .catch(error => {
        console.log(error)
        this.errored = true
      })
  },
  methods: {
    slide(dir) {
      this.direction = dir;
      dir === 1
        ? (this.transitionName = "slide-next")
        : (this.transitionName = "slide-prev");
      var len = this.slides.length;
      this.current = (this.current + dir % len + len) % len;
    } ,
    slide_mobile(dir_mobile) {
      this.direction_mobile = dir_mobile;
      dir_mobile === 1
        ? (this.transitionName_mobile = "slide-next-mobile")
        : (this.transitionName_mobile = "slide-prev-mobile");
      
      var len_mobile = this.slides_mobile.length;
      this.current_mobile = (this.current_mobile + dir_mobile % len_mobile + len_mobile) % len_mobile;
    console.log(this.current_mobile, "dirm obile");
    }

  },
  components : {
    modal
  }
}
</script>

<style scoped>

.play-vid {
    position: absolute;
    top: 33%;
    left: 24%;
    z-index: 2;
}

.see-more{
  background-color: #0A6FF1;
  border-color: #0A6FF1;
  border: none;
  color: white;
  border-radius: 30px;
  margin-bottom: 2rem;
  margin-top: 1rem;
  font-size: 25px;
}
.button-blue{
  margin-right: 2.5rem;
  margin-left: 2.5rem;
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
  font-size: 22px;
}
.banner{
  width: 100%;
}
.landing_4{
  width: 100%;
}


.see-more-3{
  background-color: white;
  border-color: white;
  border: none;
  color: black;
  border-radius: 30px;
  margin-bottom: 2rem;
  /* margin-top: -9rem; */
  font-size: 25px;
  position: absolute;
  left: 70%;
  top: 80%;
  transform: translate(-50%, -50%);
}



.hero-1 {
  margin-top: -4rem;;
}
.presentation{
  text-align: center;
  margin-top: 2rem;
}
.title > h4 > b {
  word-wrap: break-word;
  width: 200px;
  /* margin-left: 45%; */
}
.title > h6 > b {
  word-wrap: break-word;
  width: 200px;
  /* margin-left: 45%; */
}

.game-data {
  /* max-width: 40%; */
  text-align: left;
  color: white;
}

.icon-details {
  height: 200px;
}

.span-right {
  float: right;

}
.data-div {
  width: 50%;
}
.data {
  /* background-color: #5C101D; */
  background-image: url("../assets/bg_details.jpg");
  padding-top: 3rem;
  padding-bottom: 3rem;
}

/* slider start */
.fade-enter-active {
  transition: opacity 1s;
}
.fade-enter {
  opacity: 0;
}

/* GO TO NEXT SLIDE */
.slide-next-enter-active,
.slide-next-leave-active {
  transition: transform 0.5s ease-in-out;
}
.slide-next-enter {
  transform: translate(100%);
}
.slide-next-leave-to {
  transform: translate(-100%);
}

/* GO TO PREVIOUS SLIDE */
.slide-prev-enter-active,
.slide-prev-leave-active {
  transition: transform 0.5s ease-in-out;
}
.slide-prev-enter {
  transform: translate(-100%);
}
.slide-prev-leave-to {
  transform: translate(100%);
}

/* SLIDES CLASSES */
/* 
.blue {
  background: #4a69bd;
}

.red {
  background: #e55039;
}

.yellow {
  background: #f6b93b;
} */

/* SLIDER STYLES */
/* body {
  overflow: hidden;
  margin: 0;
  font-size: 50px;
  font-family: "Crimson Text", sans-serif;
  color: #fff;
} */

#slider {
  width: 100%;
  height: 50vh;
  position: relative;
}

.slide {
  width: 100%;
  height: 50vh;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn {
  z-index: 10;
  cursor: pointer;
  /* border: 3px solid #fff; */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
  position: absolute;
  top: calc(50% - 35px);
  left: 1%;
  transition: transform 0.3s ease-in-out;
  user-select: none;
}

.btn-next {
  left: auto;
  right: 1%;
}

.btn:hover {
  transform: scale(1.1);
}

/* slider end */

/* slider mobile start */

/* slider start */
.fade-enter-active-mobile {
  transition: opacity 1s;
}
.fade-enter-mobile {
  opacity: 0;
}

/* GO TO NEXT SLIDE */
.slide-next-mobile-enter-active,
.slide-next-mobile-leave-active {
  transition: transform 0.5s ease-in-out;
}
.slide-next-mobile-enter {
  /* transform: translate(60%); */
}
.slide-next-mobile-leave-to {
  /* transform: translate(-60%); */
}

/* GO TO PREVIOUS SLIDE */
.slide-prev-mobile-enter-active,
.slide-prev-mobile-leave-active {
  transition: transform 0.5s ease-in-out;
}
.slide-prev-mobile-enter {
  transform: translate(-60%);
}
.slide-prev-mobile-leave-to {
  transform: translate(60%);
}


#slider-mobile {
  width: 100%;
  height: 50vh;
  position: relative;
}

.slide-mobile {
  width: 100%;
  height: 50vh;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-mobile {
  z-index: 10;
  cursor: pointer;
  /* border: 3px solid #fff; */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
  position: absolute;
  top: calc(50% - 35px);
  left: 1%;
  transition: transform 0.3s ease-in-out;
  user-select: none;
}

.btn-next-mobile {
  left: auto;
  right: 1%;
}

.btn-mobile:hover {
  transform: scale(1.1);
}

/* slider end */
.btnPrimary {
    position: relative !important;
}

a {
  text-decoration: none !important;
}

.iphone-frame {
  z-index: 1;
  position: relative;
  width: 50%;
}

.slide-photo{
  width: 46%;
  height: 352px;
  border-radius: 10%;
  margin-top: -6rem;
}

</style>
<template>
  <div class="games-list">
        <div class="container">
          <div style="text-align:center;">
            <h1><b>CATEGORIES</b></h1>
            <p>SEO Description Hey , Luke! May the Force be with you. I call it luck <br> The plans you refer to will soon be back in our hands.</p>
          </div>
          <div class="row">
              <div class="col-md-4">
                  <img class="mx-auto d-block" height="200px" src="../assets/slots_category.png" alt="">
              </div>
              <div class="col-md-4">
                  <img class="mx-auto d-block" height="200px" src="../assets/table_category.png" alt="">
              </div>
              <div class="col-md-4">
                  <img class="mx-auto d-block" height="200px" src="../assets/instant_category.png" alt="">
              </div>
          </div>
          <div class="games">
            <div class="row">
              <div class="col-md-6">
                <h1 class="title-type" ><b>SLOTS</b></h1>
              </div>
              <div class="col-md-6">
                <p class="p-description">
                  SEO Description Hey , Luke ! May the Force be with you.I call it luck.
                  <br>The plans you refer to will soon be back in our hands.
                </p>
              </div>  
              
            </div>
            
            <div class="row">
              <div class="col-md-4" v-for="(slots,sIndex_slot) in toBeShown_slots" :key="sIndex_slot">
                <!-- <a :href=product.iframe_url> -->
                <router-link :to="{ name: 'GameDetails', params: { id: slots.game_id }}">
                  <img class="mx-auto d-block game-image" :src=slots.thumbnail alt="" width="100%" height="200px">
                  <h5><b>{{slots.name}} </b></h5>
                </router-link>
                <!-- </a> -->
              </div>
            </div>
          </div>
          <div>
            <!-- <button @click="prevPage" :disabled="currentPage==1">Show Less</button> -->
            <button class="btn-lg mx-auto d-block see-latest" @click="nextPage_slots" :disabled="currentPage_slots == totalPages_slots"><p class="button-blue" > > &nbsp; See More</p></button>
          </div>
          
          <div class="games">
            <div class="row">
              <div class="col-md-6">
                <h1 class="title-type"><b>TABLE GAMES</b></h1>
              </div>
              <div class="col-md-6">
                <p class="p-description">
                  SEO Description Hey , Luke ! May the Force be with you.I call it luck.
                  <br>The plans you refer to will soon be back in our hands.
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4" v-for="(tables,pIndex_tables) in toBeShown_table" :key="pIndex_tables">

                <router-link :to="{ name: 'GameDetails', params: { id: tables.game_id }}">
                  <img class="mx-auto d-block game-image" :src=tables.thumbnail alt="" width="100%" height="200px">
                  <h5><b>{{tables.name}} </b></h5>
                </router-link>
              </div>
            </div>
          </div>
          <div>
            <button class="btn-lg mx-auto d-block see-latest" @click="nextPage_table" :disabled="currentPage_table == totalPages_table"><p class="button-blue" > > &nbsp; See More</p></button>
          </div>
      
        
          <div class="games">
            <div class="row">
              <div class="col-md-6">
                <h1 class="title-type"><b>SCRATCH CARDS</b></h1>
              </div>
              <div class="col-md-6">
                <p class="p-description">
                  SEO Description Hey , Luke ! May the Force be with you.I call it luck.
                  <br>The plans you refer to will soon be back in our hands.

                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4" v-for="(instant,pIndex_instant) in toBeShown_instant" :key="pIndex_instant">
                <router-link :to="{ name: 'GameDetails', params: { id: instant.game_id }}">
                  <img class="mx-auto d-block game-image" :src=instant.thumbnail alt="" width="100%" height="200px">
                  <h5><b>{{instant.name}} </b></h5>
                </router-link>
              </div>
            </div>
          </div>
          <div>
            <button class="btn-lg mx-auto d-block see-latest" @click="nextPage_instant" :disabled="currentPage_instant == totalPages_instant"><p class="button-blue" > > &nbsp; See More</p></button>
          </div> 

           <div class="games">
             <div class="row">
              <div class="col-md-6">
                <h1 class="title-type"><b>OTHERS</b></h1>
              </div>
              <div class="col-md-6">
                <p class="p-description">
                  SEO Description Hey , Luke ! May the Force be with you.I call it luck.
                  <br>The plans you refer to will soon be back in our hands.

                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4" v-for="(others,pIndex_others) in toBeShown_others" :key="pIndex_others">
                <router-link :to="{ name: 'GameDetails', params: { id: others.game_id }}">
                  <img class="mx-auto d-block game-image" :src=others.thumbnail alt="" width="100%" height="200px">
                  <h5><b>{{others.name}} </b></h5>
                </router-link>
              </div>
            </div>
          </div>
          <div>
             <button class="btn-lg mx-auto d-block see-latest" @click="nextPage_others" :disabled="currentPage_others == totalPages_others"><p class="button-blue" > > &nbsp; See More</p></button>
          </div> 
        </div>
        
     <div style="position:relative">
      <img class="landing_4" src="../assets/banner3_main.png">
      <button class=" btn-lg mx-auto d-block see-more-3" ><p class="button-blue" ><router-link style="color:black" to="/GamesPortofolio"> > &nbsp; See more</router-link></p></button>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import axios from 'axios'

export default {
  data () {
    return {
      products: [] ,
      slots : [] ,
      tables : [] , 
      instant: [] ,
      others : [] , 
      currentPage_slots: 1,
      currentPage_table: 1,
      currentPage_instant: 1 , 
      currentPage_others: 1

    }
    
  },
  mounted(){
      this.products = []
      axios
      .get('https://flipluck.com/api/v1/games')
      .then(response => {
        this.products = response.data;
        this.products.forEach(element => {
          if(element.category_id == 9 ){
            this.instant.push(element)
          }else if(element.category_id == 8 ){
            this.tables.push(element);
          }else if(element.category_id == 7){
            this.slots.push(element);
          }else if (element.category_id == 10){
            this.others.push(element);
          }
        });
      })
      .catch(error => {
        console.log(error)
        this.errored = true
      })

  },
   computed:{
  	toBeShown_slots() {
    	return this.slots.slice(0, this.currentPage_slots * 6);
    },
    totalPages_slots() {
    	return Math.ceil( this.slots.length / 6);
    },
    toBeShown_table() {
    	return this.tables.slice(0, this.currentPage_table * 6);
    },
    totalPages_table() {
    	return Math.ceil( this.tables.length / 6);
    },
    toBeShown_instant() {
    	return this.instant.slice(0, this.currentPage_instant * 6);
    },
    totalPages_instant() {
    	return Math.ceil( this.instant.length / 6);
    },
    toBeShown_others() {
    	return this.others.slice(0, this.currentPage_others * 6);
    },
    totalPages_others() {
    	return Math.ceil( this.others.length / 6);
    }
  },
  methods: {
  	nextPage_slots(){
    	if(this.currentPage_slots <  this.totalPages_slots) this.currentPage_slots++;
    },
    nextPage_table(){
    	if(this.currentPage_table <  this.totalPages_table) this.currentPage_table++;
    },
    nextPage_instant(){
    	if(this.currentPage_instant <  this.totalPages_instant) this.currentPage_instant++;
    },
    nextPage_others(){
    	if(this.currentPage_others <  this.totalPages_others) this.currentPage_others++;
    },
    prevPage(){
    	this.currentPage = this.currentPage - 1 || 1;
    }
  }
}
</script>

<style scoped>

.see-more-3{
  background-color: white;
  border-color: white;
  border: none;
  color: black;
  border-radius: 30px;
  margin-bottom: 2rem;
  /* margin-top: -9rem; */
  font-size: 25px;
  position: absolute;
  left: 70%;
  top: 80%;
  transform: translate(-50%, -50%);
}


.title-type {
  /* padding-top: 2rem; */
  color:orange;
}
.p-description {
  float: right;
  font-size: 12px;
} 
.button-blue{
  margin-right: 2.5rem;
  margin-left: 2.5rem;
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
  font-size: 22px;
}
.landing_4{
  width: 100%;
}
.conntainer {
  text-align: center;
}
h5 {
  text-align: center;
  color: #000;
}
.row{
  margin-top: 1rem;
}
.see-more{
  background-color: #0A6FF1;
  border-color: #0A6FF1;
  border: none;
  color: white;
  border-radius: 20px;
  margin-bottom: 2rem;
  margin-top: 1rem;
}

.games {

  margin-top: 5rem;

}

.see-latest{
  background-color: #0A6FF1;
  border-color: #0A6FF1;
  border: none;
  color: white;
  border-radius: 30px;
  margin-bottom: 2rem;
  margin-top: 2rem;
  font-size: 25px;
}
h5{
  font-size: 16px;
}

a {
  text-decoration: none !important;
}

.game-image{
  margin-bottom: 1rem;
}

.col-md-4 {
  margin-bottom: 2rem;
}

</style>
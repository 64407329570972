<template>
  <div class="container">
    <div class="row">
      <div class="col-md-1"></div>
      <div class="banner col-md-5">
        <img class="about-image" src="../assets/img_8.png">
      </div>
      <div class="about-text col-md-5">
        <h1><b>ABOUT US</b></h1>
        <br>
        <p>
          A stealth studio with offices in Spain and Bucharest that created more than 200 games for casual and casino markets. Contact us and let us know your project: sales@flipluck.com
        </p>
        <p>
          Hey, Luke! May the Force be with you,I call it luck. The plans you refer to will soon be back in our hands.
        </p>
        <div class="social">
          <div class="social-div"><a style="color:orange" href=""><BIconFacebook/></a></div>
          <div class="social-div"><a style="color:orange" href=""><BIconInstagram/></a></div>
          <div class="social-div"><a style="color:orange" href=""><BIconTwitter/></a></div>
        </div>
      </div>
    
    </div>
    
  </div>
</template>
<style scoped>
.banner {
  display: inline;
  float: right;
  
}
img {
  max-width: 100%;
} 
.about-text{
  margin-top: 5rem;
}

@media only screen and (max-width: 998px) {
  .container {
    height: auto;
  }
  .social-div {
    display: inline; 
    margin-left: 3rem;
  }
  .social-div:first-of-type{
    margin-left: 0rem;
  }
  svg {
    width: auto;
    height: 40px;
  }
  .social{
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 2rem;

  }
  .about-text > h1{
    text-align: center;
  }
}
@media only screen and (min-width: 998px) {
  .container {
    height: 39rem;
  }
  svg {
    width: auto;
    height: 40px;
  }
  .social-div {
    display: inline; 
    margin-left: 3rem;
  }
  .social-div:first-of-type{
    margin-left: 0rem;
  }
  .banner{
    margin-top: 4rem;
  }
  .social{
    margin-top: 4rem;
  }
}
 
</style>

<script>
import { BIconFacebook , BIconInstagram , BIconTwitter} from 'bootstrap-vue'

export default {
  
    components:{
        BIconFacebook ,
        BIconInstagram,
        BIconTwitter
    }
  }

</script>